<template>
  <div class="wrapper" id="upgrade">
    <ctheader></ctheader>
    <div ref="marketGroup" class="market-group">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="群组名称">
          <el-input
            v-model="formInline.version_name"
            placeholder="群组名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add" size="small">新增</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="market_group_list"
        style="width: 100%"
        :header-cell-style="getRowClass"
      >
        <el-table-column prop="code" label="群组编号"> </el-table-column>
        <el-table-column prop="name" label="群组名称"> </el-table-column>
        <el-table-column prop="market_names" label="关联市场">
        </el-table-column>
        <el-table-column prop="created_by" label="创建人"> </el-table-column>
        <el-table-column label="绑定时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | filterSecond }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small">
              编辑
            </el-button>
            <el-button @click="detail(scope.row)" type="text" size="small">
              查看详情
            </el-button>
            <el-button @click="set(scope.row, 1)" type="text" size="small">
              监管主屏设置
            </el-button>
            <el-button @click="set(scope.row, 2)" type="text" size="small">
              监管副屏设置
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :start="formInline.start"
        :total="market_group_total"
        :page-length="formInline.length"
        :pageCallback="getUpgradeList"
      ></pagination>
      <el-drawer
        :title="title"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose"
        size="60%"
      >
        <div class="gird-data">
          <el-input
            v-model="drawForm.name"
            placeholder="请输入群组名称"
          ></el-input>

          <el-table
            :data="girdData"
            :row-key="getRowKeys"
            @select="userHandleChange"
            @select-all="selcectAllChange"
            style="width: 100%;margin-top:20px"
            :header-cell-style="getRowClass"
            height="450"
            ref="multipleApiTable"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column prop="market_name" label="市场名称">
            </el-table-column>
            <el-table-column prop="area" label="所在行政区划">
            </el-table-column>
            <el-table-column prop="addr_detail" label="详细地址">
            </el-table-column>
            <el-table-column prop="company_name" label="所属客户">
            </el-table-column>
            <el-table-column prop="expiration_status_name" label="状态">
            </el-table-column>
            <el-table-column prop="expiration_date" label="有效期">
            </el-table-column>
          </el-table>
          <pagination
            :start="gridForm.start"
            :total="market_total"
            :page-length="gridForm.length"
            :pageCallback="getMarketList"
          ></pagination>
          <el-button
            type="primary"
            size="small"
            style="margin-top:12px"
            @click="save"
            v-if="title != '查看详情'"
          >
            保存
          </el-button>
        </div>
      </el-drawer>
      <el-drawer
        title="监管屏设置"
        :visible.sync="drawer1"
        :direction="direction"
        size="60%"
      >
        <div style="padding:20px">
          <el-input
            v-model="set_title"
            placeholder="请输入页面标题"
            size="small"
            style="width:300px;margin-bottom:12px"
          ></el-input>
          <el-upload
            action="/api/menu"
            :data="{ action: 'company.logo.upload' }"
            list-type="picture-card"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :file-list="imageList"
            :before-upload="beforeUpload"
            :limit="1"
            v-if="settingForm.screen_type == 1"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <br />
          <div style="margin-bottom:12px" v-if="settingForm.screen_type == 1">
            <span style="margin-right: 12px;">是否展示天气</span>
            <el-radio-group v-model="is_show_weather">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </div>
          <div style="margin-bottom:12px" v-if="settingForm.screen_type == 1">
            <span style="margin-right: 12px;">是否展示时间</span>
            <el-radio-group v-model="is_show_date">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </div>
          <div style="margin-bottom:12px" v-if="settingForm.screen_type == 1">
            <span style="margin-right: 12px;">数据取值</span>
            <el-radio-group v-model="is_show_sale">
              <el-radio label="1">交易结算</el-radio>
              <el-radio label="2">称重结算</el-radio>
            </el-radio-group>
          </div>

          <el-button
            type="primary"
            size="small"
            style="margin-top:20px"
            @click="settingSave"
          >
            保存
          </el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import ctheader from "../../components/ctheader";
import pagination from "../../components/pagination";
import router from "../../router";
import * as URL from "../../modules/URLs";
import APIs from "@/modules/APIs";
import { apiPost } from "../../modules/utils";
import moment from "moment";
import { mapActions } from "vuex";
import * as imageConversion from "image-conversion";

const actions = mapActions(["setAlert"]);

export default {
  name: "",
  components: { ctheader, pagination },

  mixins: [],
  props: {},
  data() {
    return {
      formInline: {
        action: "lists",
        start: 0,
        length: 10,
      },
      market_group_total: 0,
      market_group_list: [],
      drawer: false,
      direction: "rtl",
      title: "新增",
      drawForm: {},
      girdData: [],
      gridForm: {},
      market_total: 0,
      changeId: [], //选中的所有Id
      selectAdd: [], //当前页所选中的id
      imageList: [],
      drawer1: false,
      set_title: "",
      settingForm: {},
      is_show_weather: "1",
      is_show_date: "1",
      is_show_sale: "1",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getUpgradeList();
  },
  filters: {
    filterSecond(num) {
      return num ? moment.unix(num).format("YYYY-MM-DD HH:mm:ss") : "";
    },
  },
  methods: {
    ...actions,
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #e9f2f1;font-size:12px;color:#525e6e;border:0;font-weight:normal";
      } else {
        return "";
      }
    },
    //群组列表
    getUpgradeList(index = 0) {
      this.formInline.action = "lists";
      this.formInline.start = index;
      let $this = this;
      apiPost({
        url: APIs.MARKETGROUP,
        data: $this.formInline,
        success(res) {
          if (res.code === "0") {
            $this.market_group_list = res.data.map((item) => {
              return {
                ...item,
                market_names:
                  item.market_name.length < 5
                    ? item.market_name.map((c) => c.name).join(",")
                    : item.market_name
                        .slice(0, 5)
                        .map((c) => c.name)
                        .join(",") + "...",
              };
            });
            $this.market_group_total = res.total;
          } else {
            $this.market_group_list = [];
            $this.market_group_total = 0;
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    //市场列表
    getMarketList(index = 0) {
      this.gridForm.action = "market.list";
      this.gridForm.start = index;
      let $this = this;
      apiPost({
        url: APIs.COMPANY,
        data: this.gridForm,
        // data: Object.assign({ action: "market.list" }, $this.search),
        success(res) {
          if (res.code === "0") {
            $this.girdData = res.data.map((el) => {
              el.expiration_status_name = "";
              if (el.expiration_status === 1) {
                el.expiration_status_name = "未过期";
                el.className = "gray";
              } else if (el.expiration_status === 2) {
                el.expiration_status_name = "即将过期";
                el.className = "yellow";
              } else if (el.expiration_status === 3) {
                el.expiration_status_name = "已过期";
                el.className = "red";
              }
              return el;
            });
            $this.market_total = res.total.total;
            $this.selectAdd = $this.changeId.filter((item) =>
              $this.girdData.some((ele) => ele.id === item)
            ); //拿到当页匹配的设备id
            console.log("已有设备id数组", $this.changeId, $this.selectAdd);
            //回显选中数据
            $this.$nextTick(() => {
              $this.girdData.forEach((item, index) => {
                $this.selectAdd.forEach((item1, index1) => {
                  if (item.id == item1) {
                    $this.$refs.multipleApiTable.toggleRowSelection(
                      $this.girdData[index],
                      true
                    );
                  }
                });
              });
            });
          } else {
            $this.data = [];
            // $this.config.total = 0;
            $this.total = {};
          }
        },
        error(res) {
          $this.data = [];
          //   $this.config.total = 0;
          $this.total = {};
        },
      });
    },
    save() {
      if (this.title == "新增") {
        this.drawForm.action = "add";
      } else {
        this.drawForm.action = "edit";
        console.log(this.drawForm.market_ids);
      }
      let $this = this;

      apiPost({
        url: APIs.MARKETGROUP,
        data: $this.drawForm,
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.drawer = false;
            $this.getUpgradeList();
            $this.title = "";
            $this.drawForm.market_ids = [];
            $this.drawForm.name = "";
            $this.changeId = []; //选中的所有Id
            $this.selectAdd = [];
            $this.girdData = [];
            $this.$refs.multipleApiTable.clearSelection();
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    query() {
      this.gridForm.start = 0;
      this.getUpgradeList();
    },
    add() {
      this.title = "新增";
      this.drawer = true;
      this.getMarketList();

      console.log(this.changeId);
      console.log(this.selectAdd);
    },
    edit(val) {
      this.title = "编辑";
      console.log(val);
      this.drawer = true;
      this.drawForm = {
        name: val.name,
        id: val.id,
      };
      this.drawForm.market_ids = val.market_name.map((item) => item.id);
      this.changeId = val.market_name.map((item) => item.id);
      this.getMarketList();
    },
    detail(val) {
      this.title = "查看详情";
      console.log(val);
      this.drawer = true;
      this.drawForm = {
        name: val.name,
        id: val.id,
      };
      this.changeId = val.market_name.map((item) => item.id);
      this.getMarketList();
    },
    set(val, val1) {
      this.drawer1 = true;
      this.settingForm.supervise_id = val.id;
      this.settingForm.screen_type = val1;

      const params = {
        action: "setting.get",
        supervise_id: val.id,
        screen_type: this.settingForm.screen_type,
      };
      let $this = this;
      apiPost({
        url: APIs.MARKETGROUP,
        data: params,
        success(res) {
          if (res.code === "0") {
            $this.set_title = res.data.title;
            $this.is_show_weather = res.data.is_show_weather;
            $this.is_show_date = res.data.is_show_date;
            $this.is_show_sale = res.data.is_show_sale;
            $this.imageList = res.data.image_url
              ? [
                  {
                    url: res.data.image_url,
                    name: "",
                  },
                ]
              : [];
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    handleRemove(file) {
      console.log(file);
      this.imageList = this.imageList.filter((c) => c.uid != file.uid);
    },
    handleSuccess(res) {
      if (res.code == 0) {
        const { data } = res;
        const image = [data].map((c) => ({
          url: "http://" + c.domain + "/" + c.path,
        }));
        console.log(image);
        this.imageList = [...this.imageList, ...image];
        console.log(this.imageList);
      } else {
        this.$message.error(res.msg);
      }
    },
    beforeUpload(file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg"
      ) {
        this.$message.warning("只能上传照片");
        return false;
      }

      return new Promise((resolve) => {
        console.log(file);
        let check = file.size / 1024 < 300; // 判定图片大小是否小于500KB
        if (check) {
          resolve(file);
          console.log(file);
        } else {
          console.log(file);
          this.$confirm(
            "您上传的图片过大,是否进行压缩上传？(此操作会降低图片质量)",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              imageConversion.compressAccurately(file, 200).then((res) => {
                res = new File([res], file.name, {
                  type: res.type,
                  lastModified: Date.now(),
                });
                resolve(res);
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消上传",
              });
              this.imageList = this.imageList.filter((c) => c.uid !== file.uid);
            });
        }
      });
    },
    settingSave() {
      this.settingForm = {
        ...this.settingForm,
        action: "setting.set",
        title: this.set_title,
        is_show_date: this.is_show_date,
        is_show_sale: this.is_show_sale,
        is_show_weather: this.is_show_weather,
        image_url: this.imageList.length != 0 ? this.imageList[0].url : "",
      };

      let $this = this;

      apiPost({
        url: APIs.MARKETGROUP,
        data: $this.settingForm,
        success(res) {
          if (res.code === "0") {
            $this.setAlert({ msg: res.msg, type: true });
            $this.drawer1 = false;
            $this.getUpgradeList();
          } else {
            $this.setAlert({ msg: res.msg });
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    handleClose() {
      this.drawer = false;
      this.drawer1 = false;
      this.drawForm.market_ids = [];
      this.drawForm.name = "";
      this.changeId = []; //选中的所有Id
      this.selectAdd = [];
      this.girdData = [];

      this.$refs.multipleApiTable.clearSelection();
    },
    getRowKeys(row) {
      return row.id;
    },
    //当用户手动勾选数据行的 Checkbox 时触发的事件
    userHandleChange(val, row) {
      console.log("进1", val, row);
      //如果手动勾选所有设备按钮为选中状态,则初始化按钮,并显示分页
      // 拿到当前页选中的id数组
      if (val != null && val != "") {
        var numbers = "";
        for (let obj of val) {
          // console.log("选中", obj.id);
          if (numbers == "") {
            numbers += obj.id;
          } else {
            numbers += "," + obj.id;
          }
        }
        this.newArr = numbers;
        this.downArr = numbers.split(",");
        //目前选中的数组id
        let changeType = this.downArr.map(Number);
        //要添加的过滤新数据数组
        const _arr1 = changeType.filter(
          (item1) => !this.selectAdd.includes(item1)
        );
        if (_arr1.length > 0) {
          //有新元素
          // this.changeId后台返的回显id数组
          //将新数组添加进原数组id
          this.changeId = Array.from(new Set([...this.changeId, ..._arr1]));
          this.selectAdd = Array.from(new Set([...this.selectAdd, ..._arr1]));
          // console.log('过滤',_arr1,'添加后的原数组id', this.changeId )
        } else {
          //删除元素操作
          //要删除的数据数组
          const _arr2 = this.selectAdd.filter(
            (item2) => !changeType.includes(item2)
          );
          //将要删除的数组从原数组中删掉
          this.changeId.splice(
            this.changeId.findIndex((item) => item == _arr2),
            1
          );
          this.selectAdd.splice(
            this.selectAdd.findIndex((item) => item == _arr2),
            1
          );
        }
        console.log(
          "当前选中",
          changeType,
          "当前页设备id",
          this.selectAdd,
          "原数组id",
          this.changeId
        );
        this.drawForm.market_ids = changeType;
      } else {
        this.newArr = "";
        this.downArr = [];
      }
    },
    //全选/取消
    selcectAllChange(val) {
      // console.log('全部',val);
      let arr = [];
      //拿到当前页全选的数组id
      val.forEach((item, index) => {
        arr.push(item.id);
      });
      let arr1 = arr.filter((item1) => !this.selectAdd.includes(item1));
      if (arr1.length > 0) {
        //有新元素,往原数组和当前分页选中数组添加
        //原数组数据
        this.changeId = Array.from(new Set([...this.changeId, ...arr1]));
        //当前分页数组
        this.selectAdd = Array.from(new Set([...this.selectAdd, ...arr1]));
      } else {
        //删除所有元素操作
        let arr2 = this.selectAdd.filter((item2) => !arr.includes(item2));
        //删除原数组中当前页全选中的所有id
        this.changeId = this.changeId.filter((item) => {
          return !arr2.includes(item);
        });
        //删除当前选中数组中全选中的所有id
        this.selectAdd = this.selectAdd.filter((item) => {
          return !arr2.includes(item);
        });
      }
      console.log(
        "当前选中",
        arr,
        "当前页设备id",
        this.selectAdd,
        "原数组id",
        this.changeId,
        "新元素",
        arr1
      );
      this.drawForm.market_ids = arr;
    },
  },
};
</script>
<style lang="less" scoped>
.market-group {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
  .gird-data {
    padding: 24px;
  }
}
</style>
